@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,600i,700,700i&amp;subset=cyrillic,latin&amp;display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i&amp;subset=cyrillic,latin&amp;display=swap');

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 99999s;
}

body {
  overflow: hidden;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #4f4f4f;
  background-clip: padding-box;
  /*border: 8px solid transparent;*/
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 0 8px;
  color: #fff8f8;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
